import {Grid, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {getGeneralStatistics} from 'src/utils/AdminApi';
import {getCountString2} from 'src/utils/helperMethods';
import {getSiteCurrency} from 'src/utils/selectors';
import {accountIs} from 'src/utils/siteUtils';
import ToggleButtons from '../../components/ToggleButtons';
import StatsItem from './StatsItem';
import {DateTime} from 'luxon';
import {formatAmountString} from 'vetrina-js-utils/moneyUtils';
import {getNumber} from 'src/utils/numberUtils';
import {Link} from 'react-router-dom';
import InfoTip from 'src/components/ui/InfoTip';
import Picker from 'src/components/inputs/Picker';
import {getDynamicMonthListWithDates, getStartAndEndOfToday} from 'src/utils/date';
import useSite from 'src/hooks/useSite';
import getRamadanTimes from 'src/utils/getRamadanTimes';

const current_year = new Date().getFullYear();
const YEARS = [current_year - 3, current_year - 2, current_year - 1, current_year];

const {start, end} = getRamadanTimes(); // getStartAndEndOfToday();

const dateRangeFilters = {
	today: {
		key: 'today',
		label: 'اليوم',
	},
	monthly: {
		key: 'monthly',
		monthIndex: new Date().getMonth(),
		year: current_year,
		label: 'شهري',
	},
	yearly: {
		key: 'yearly',
		year: current_year,
		label: 'سنوي',
	},
	custom: {
		key: 'custom',
		label: 'مخصص',
		day_gte: start,
		day_lte: end,
	},
};

const toggleButtons = Object.values(dateRangeFilters).map((date) => ({
	label: date.label,
	value: date.key,
}));

export const initalStats = {
	numberOfCustomers: 0,
	totalIncome: 0,
	top5Products: [],
	numberOfDeliveredOrders: 0,
	numberOfCancelledOrders: 0,
	numberOfReturnedOrders: 0,
	totalIncomeOfDeliveredOrders: 0,
	numberOfActiveOrders: 0,
	totalCosts: 0,
	productsCount: 0,
	expected_revenue: 0,
	settled_orders_revenue: 0,
	settled_orders_count: 0,
};

const months = [
	'يناير',
	'فبراير',
	'مارس',
	'إبريل',
	'مايو',
	'يونيو',
	'يوليو',
	'أغسطس',
	'سبتمبر',
	'أكتوبر',
	'نوفمبر',
	'ديسمبر',
];
export default function DateRangeStats() {
	const [selectedRange, setSelectedRange] = useState(dateRangeFilters.custom);
	const {config} = useSite();
	// const {start_time, close_time} = adjustTimesToCurrentDate(config?.start_time, config?.close_time);
	// console.log('🚀 ~ DateRangeStats ~ start_time, end_time:', start_time, close_time);
	const currency = getSiteCurrency();

	// const stats = initalStats;
	const {data: stats = initalStats} = useQuery(
		[
			selectedRange.key,
			selectedRange.monthIndex,
			selectedRange.year,
			selectedRange.day_gte,
			selectedRange.day_lte,
		],
		() => {
			if (selectedRange.key === dateRangeFilters.custom.key) {
				return getGeneralStatistics(
					new Date(selectedRange.day_gte).toISOString(),
					new Date(selectedRange.day_lte).toISOString(),
				);
			}

			if (selectedRange.key === dateRangeFilters.today.key) {
				const fromDate = DateTime.local().startOf('day').toUTC().toISO();
				const toDate = DateTime.local().endOf('day').toUTC().toISO();
				return getGeneralStatistics(fromDate, toDate);
			}

			if (selectedRange.key === dateRangeFilters.monthly.key) {
				const fromDate = DateTime.local()
					.set({year: selectedRange.year, month: selectedRange.monthIndex + 1})
					.startOf('month')
					.toUTC()
					.toISO();
				const toDate = DateTime.local()
					.set({year: selectedRange.year, month: selectedRange.monthIndex + 2})
					.startOf('month')
					.toUTC()
					.toISO();

				return getGeneralStatistics(fromDate, toDate);
			}

			const fromDate = DateTime.local()
				.set({year: selectedRange.year})
				.startOf('year')
				.toUTC()
				.toISO();
			const toDate = DateTime.local()
				.set({year: selectedRange.year + 1})
				.startOf('year')
				.toUTC()
				.toISO();
			return getGeneralStatistics(fromDate, toDate);
		},
		{
			// we will try to fetch new stats after 10s of last attempt
			// staleTime: 1000 * 10,
			refetchOnWindowFocus: false,
		},
	);

	return (
		<div spacing={2}>
			<Stack
				mb={2}
				spacing={1}
				alignItems="center"
				direction={{sm: 'row', xs: 'column'}}
				justifyContent="space-between"
			>
				<Typography variant="h4">المبيعات</Typography>
				<Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
					<ToggleButtons
						onChange={(e) => {
							if (e.target.value === dateRangeFilters.today.key)
								setSelectedRange(dateRangeFilters[e.target.value]);
							// keep the selected year from navigating from monthly to yearly
							else {
								const year = selectedRange.year ?? dateRangeFilters[e.target.value].year;

								setSelectedRange({
									...dateRangeFilters[e.target.value],
									year,
								});
							}
						}}
						value={selectedRange.key}
						buttons={toggleButtons}
					/>

					{selectedRange.key === dateRangeFilters.monthly.key && (
						<Picker
							defaultValue={months[selectedRange.monthIndex]}
							sx={{width: 100}}
							label="الشهر"
							options={months}
							onChange={(e) => {
								setSelectedRange({
									...selectedRange,
									monthIndex: months.findIndex((i) => i === e.target.value),
								});
							}}
						/>
					)}

					{selectedRange.key !== dateRangeFilters.today.key &&
						selectedRange.key !== dateRangeFilters.custom.key && (
							<Picker
								value={selectedRange.year}
								sx={{width: 100}}
								label="السنة"
								options={YEARS}
								onChange={(e) => {
									setSelectedRange({
										...selectedRange,
										year: +e.target.value,
									});
								}}
							/>
						)}
				</Stack>
			</Stack>
			{selectedRange.key === dateRangeFilters.custom.key && (
				<Stack my={2} spacing={3} direction="row">
					<TextField
						InputLabelProps={{shrink: true}}
						// pattern="\d{4}-\d{2}-\d{2}"
						type="datetime-local"
						fullWidth
						label="من"
						placeholder="من"
						onChange={(e) =>
							setSelectedRange({
								...selectedRange,
								day_gte: e.target.value,
							})
						}
						// defaultValue={start_time}
						// 2024-03-21T02:12
						value={selectedRange.day_gte}
						required
					/>
					<TextField
						InputLabelProps={{shrink: true}}
						// pattern="\d{4}-\d{2}-\d{2}"
						type="datetime-local"
						fullWidth
						label="الي"
						placeholder="الي"
						// '2023-02-09T23:59:59.000Z'
						onChange={(e) =>
							setSelectedRange({
								...selectedRange,
								day_lte: e.target.value,
							})
						}
						value={selectedRange.day_lte}
						// defaultValue={close_time}
						required
					/>
				</Stack>
			)}

			<Grid spacing={2} container>
				<StatsItem
					key="sales_stats"
					label="طلبات تم تسويتها"
					value={formatAmountString(stats.settled_orders_revenue, currency)}
					subValue={`من ${getCountString2(stats.settled_orders_count, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي تم تسويتها مالياً'}
				/>
				<StatsItem
					key="sales_stats"
					label="طلبات تم تسليمها"
					value={formatAmountString(stats.totalIncomeOfDeliveredOrders, currency)}
					subValue={`من ${getCountString2(stats.numberOfDeliveredOrders, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي تم توصيلها او استلامها'}
				/>
				<StatsItem
					key="stats2"
					label="طلبات فعالة"
					value={formatAmountString(stats.totalIncome, currency)}
					subValue={`من ${getCountString2(stats.numberOfActiveOrders, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي قيد المراجعة او قيد التجهيز او قيد التوصيل '}
				/>
				<StatsItem key="stats4" label="طلبات ملغية" value={stats.numberOfCancelledOrders} />
				<StatsItem key="stats5" label="طلبات راجعة" value={stats.numberOfReturnedOrders} />
				<StatsItem key="productsCount" label="عدد المنتجات" value={stats.productsCount} />
				<StatsItem
					key="totalCosts"
					label="التكاليف"
					value={formatAmountString(stats.totalCosts, currency)}
				/>

				<StatsItem
					key="profit"
					label="الارباح"
					value={formatAmountString(stats.totalIncomeOfDeliveredOrders - stats.totalCosts, currency)}
					info={
						'الفرق بين مجموع مبيعات الطلبات التي تم توصيلها ومجموع المشتريات التي تم ادخالها في المنظومة'
					}
				/>
				<StatsItem
					key="profit"
					label="اجمالي المخزون"
					value={formatAmountString(stats.expected_revenue, currency)}
					info={'مجموع بيع الكميات المتوفرة'}
				/>
			</Grid>

			{/* <InfoTip title={'هذا الرقم يعبر عن مجموع الطلبات التي تم تسويتها بالاضافة الي العربون'} /> */}

			{/* {accountIs('feza', true) && (
				<>
					<Typography mt={2}>المبالغ التي تم استلامها + العربون {getNumber(stats.cash)}</Typography>
					<Stack spacing={2}>
						<Link to={`/orders?payment_received=1`}>عرض الطلبات اللي تم تسويتها</Link>
						<Link to={`/orders?payment_received=0&down_payment_gte=1`}>
							عرض الطلبات اللي ليها عربون ولم يتم تسويتها
						</Link>
					</Stack>
				</>
			)} */}
		</div>
	);
}

function adjustTimesToCurrentDate(startTimeInput, closeTimeInput) {
	// Get the current date
	const currentDate = new Date();
	const nextDate = new Date();
	// currentDate.setTime(0);
	// nextDate.setTime(0);
	nextDate.setDate(nextDate.getDate() + 1);

	if (!startTimeInput || !closeTimeInput)
		return {
			start_time: currentDate.toISOString(),
			close_time: nextDate.toISOString(),
		};

	// Extract the time from the input and combine it with the current date
	const startTime = new Date(
		`${currentDate.toISOString().split('T')[0]}T${
			new Date(startTimeInput).toISOString().split('T')[1]
		}`,
	);
	let closeTime = new Date(
		`${currentDate.toISOString().split('T')[0]}T${
			new Date(closeTimeInput).toISOString().split('T')[1]
		}`,
	);

	// Check if close time is before start time, and if so, add one day to close time
	if (closeTime <= startTime) {
		closeTime.setDate(closeTime.getDate() + 1);
	}

	console.log('startTime', startTime);
	console.log('startTime', startTime.toISOString());
	// Convert adjusted times back to ISO strings
	const adjustedStartTime = startTime.toISOString();
	const adjustedCloseTime = closeTime.toISOString();

	// Return the adjusted times
	return {
		start_time: adjustedStartTime.split('.')[0],
		close_time: adjustedCloseTime.split('.')[0],
	};
}
