import {Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {IconButton} from '@mui/material';

export default function LinkToInventory({productId, variationId}) {
	const queryParams = new URLSearchParams();
	if (variationId) {
		queryParams.append('variationId', variationId);
	}
	return (
		<Stack direction="row" alignItems="center" sx={{height: '100%'}}>
			<Link
				to={`/products/${productId}/inventory-changes${
					queryParams.toString() ? `?${queryParams}` : ''
				}`}
				style={{textDecoration: 'none'}}
			>
				<IconButton>
					<Typography>سجل الطلبات والمشتريات</Typography>
				</IconButton>
			</Link>
		</Stack>
	);
}
