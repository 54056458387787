import min from 'lodash/min';
import sumBy from 'lodash/sumBy';
import max from 'lodash/max';
import {isNullOrEmpty} from './helperMethods';
import {getNumber} from './numberUtils';
import {getSiteCurrency} from './selectors';
import {getImageFormat} from './imageUtils';
import {calcPriceAfterDiscount} from './discountUtils';

export const defaultImageSrc = '/static/default-product-image.png';
export const getImageSrc = (product) => getImageFormat(product.main_image);

const getVariationsPrice = (variations = [], showCurrency = true, withDiscount = true, product) => {
	// all are equal then return price , else return 'سعر متعدد'
	const prices = variations.map((v) =>
		calcPriceAfterDiscount(
			v.price ?? product?.price ?? 0,
			withDiscount ? (v.discount ?? product?.discount) : 0,
		),
	);
	const minPrice = min(prices);
	const maxPrice = max(prices);
	return minPrice === maxPrice
		? `${minPrice}${showCurrency ? getSiteCurrency() : ''}`
		: `${minPrice} - ${maxPrice} ${showCurrency ? getSiteCurrency() : ''}`;
};

export const getProductPrice = (product, showCurrency = true, withDiscount = true) => {
	return !isNullOrEmpty(product.variations)
		? getVariationsPrice(product.variations, showCurrency, withDiscount, product)
		: `${calcPriceAfterDiscount(product.price, withDiscount ? product.discount : 0)}${
				showCurrency ? getSiteCurrency() : ''
			}`;
};

export const getProductQuantity = (product) => {
	if (product.infinite === true) return '∞';
	if (product.derived) {
		return product.derived.branch_quantity;
	}
	return !isNullOrEmpty(product.variations)
		? sumBy(product.variations, (v) => getNumber(v.quantity))
		: product.quantity;
};

export const getPriceProperties = (product, selectedVariation = null) => {
	if (selectedVariation) {
		const price = getNumber(selectedVariation.price, product.price);
		const discount = selectedVariation.discount ?? (selectedVariation.price ? 0 : product.discount);

		return {
			hasDiscount: discount > 0,
			discount,
			price,
			finalPrice: calcPriceAfterDiscount(price, discount),
		};
	}

	if (isNullOrEmpty(product.variations)) {
		return {
			hasDiscount: product.discount > 0,
			discount: getNumber(product.discount),
			price: product.price,
			finalPrice: calcPriceAfterDiscount(product.price, product.discount),
		};
	} else {
		// we have variations
		if (product.variations.length === 1) return getPriceProperties(product, product.variations[0]);
		else {
			// i decided to always show the min price instead of the range
			let min = {value: Number.MAX_SAFE_INTEGER, index: -1};
			const varsPriceProperties = product.variations.map((v, i) => {
				const properties = getPriceProperties(product, v);
				if (properties.finalPrice < min.value) {
					min = {
						value: properties.finalPrice,
						index: i,
					};
				}

				return properties;
			});
			return varsPriceProperties[min.index];
		}
	}
};