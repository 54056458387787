import {DateTime} from 'luxon';
import {parse, stringify} from 'qs';
import {useInfiniteQuery, useQuery} from 'react-query';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {getOrders} from 'src/utils/AdminApi';
import {fromUTCdateToISO, getDateBoundaries} from 'src/utils/date';
/**
 *
 * @returns {import('react-query').UseQueryResult<TData, TError>}
 */
const limit = 9;
const useOrdersQuery = () => {
	const {filters, filtersQueryString} = useUrlFilters({ignoreNullOrEmpty: true});
	const {refetch, data, fetchNextPage, hasNextPage,isLoading} = useInfiniteQuery(
		['order', filtersQueryString],
		({pageParam = 0}) => {
			let qs = stringify(filters);

			// NOTE: we may need to do the same changes here
			// src/pages/Orders/list/Orders.list.js

			if (filters.selected_date_gte) {
				filters.selected_date_gte = fromUTCdateToISO(filters.selected_date_gte);
			}
			if (filters.selected_date_lte) {
				filters.selected_date_lte = DateTime.fromISO(fromUTCdateToISO(filters.selected_date_lte))
					.endOf('day')
					.toISO();
			}
			if (filters.fulfillment_date_gte) {
				filters.fulfillment_date_gte = new Date(filters.fulfillment_date_gte).toISOString();
			}
			if (filters.fulfillment_date_lte) {
				filters.fulfillment_date_lte = new Date(filters.fulfillment_date_lte).toISOString();
			}
			// console.log('qsfilters', qsFilters);
			return getOrders(
				`?_limit=${limit}&_start=${pageParam * limit}&_sort=id:desc&${stringify(filters)}`,
			);
		},
		{
			// will not try to check for updates until number of ms passed
			// staleTime: 1000 * 5,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);
	return {
		orders: Array.isArray(data?.pages) ? data.pages.flat() : [],
		fetchNextPage,
		hasNextPage,
		refetch,
		isLoading
	};
	// return useQuery(
	// 	['order', filtersQueryString],
	// 	() => {
	// 		// console.log('qsfilters', qsFilters);
	// 		return getOrders(`?_limit=-1&_sort=id:desc&${filtersQueryString}`);
	// 	},
	// 	{
	// 		// will not try to check for updates until number of ms passed
	// 		staleTime: 1000 * 10,
	// 		// refetchOnMount: false,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );
};

export default useOrdersQuery;
