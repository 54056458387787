import DeleteIcon from '@mui/icons-material/Delete';
import {Divider, IconButton, Stack} from '@mui/material';
import {updateOrderPermission} from 'src/permissionsList';
import {canAccess} from 'src/utils/permissions';
import {OrderProductItem} from './OrderItem.item';
import {accountIsDemo} from 'src/utils/customSite';
import {orderIsCanceledOrReturned} from 'src/utils/orderUtils';
import useUser from 'src/hooks/useUser';
import ItemBranchPicker from 'src/components/ItemBranchPicker';
import BranchPicker from 'src/components/BranchPicker';
import {request} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import {toast} from 'react-toastify';


export function OrderItemsList({
	setItemToDelete,
	orderStatus,
	isCashier,
	refetch,
	items = [],
	...props
}) {
	const lengthOfItemsToDelete = items?.filter((item) => !item.isDeleted)?.length;
	const isReturned = orderIsCanceledOrReturned({status: orderStatus});
	const userBranch = useUser().user.branch;
	// const renderDeleteButton = () => {

	// 	if(isReturned) return
	// 	if(item.isDeleted) return 'محذوف'

	// 	{(!isCashier || accountIsDemo()) &&
	// 		(!item.isDeleted
	// 			? canAccess(updateOrderPermission) && (
	// 					<IconButton size="small" sx={{opacity: 0.5}} onClick={() => setItemToDelete(item.id)}>
	// 						<DeleteIcon />
	// 					</IconButton>
	// 				)
	// 			: 'محذوف')}
	//  }
	const canChangeBranch = canAccess() && !isReturned;
	return (
		<Stack direction="column" spacing={2} {...props}>
			{items
				// .filter((i) => i.isDeleted !== true)
				.map((item, index) => {
					const notForMybranch = userBranch && userBranch != item.branch;

					return (
						// <Grid key={item.id} item xs={6} md={4} >
						<div key={item.id}>
							{/* {item.id} */}
							<Stack
								sx={{opacity: (item.isDeleted || notForMybranch) && 0.4}}
								alignItems="center"
								direction="row"
								justifyContent="space-between"
							>
								<OrderProductItem item={item} />

								<Stack spacing={2} direction="row">
									<BranchPicker
										disabled={isCashier || !canChangeBranch || item.isDeleted}
										variant="outlined"
										allowNone={false}
										value={item.branch}
										onChange={async (e, o) => {
											try {
												await request(`/order-items/${item.id}/branch`, 'PUT', {branch: o.id});
												refetch();
											} catch (err) {
												toast.error(handleRequestError(err));
											}
										}}
									/>

									{!isReturned &&
										(!item.isDeleted
											? canAccess(updateOrderPermission) && (
													<IconButton
														size="small"
														sx={{opacity: 0.5}}
														onClick={() => {
															if (lengthOfItemsToDelete <= 1) {
																return toast.error('لا يمكنك حذف جميع عناصر الطلب');
															}
															setItemToDelete(item.id);
														}}
													>
														<DeleteIcon />
													</IconButton>
												)
											: 'محذوف')}
								</Stack>
							</Stack>

							{items.length - 1 < index && <Divider />}
							{/* {item.reason} */}
						</div>
					);
				})}
		</Stack>
	);
}
