import { Box, Stack, Checkbox } from "@mui/material";
import { useCallback } from "react";

export default function DataGrid({
    rows = [],
    columns = [],
    checkboxSelection = false,
    selectionModel = [],
    onSelectionModelChange,
    components = {},
    componentsProps = {},
    rowHeight = 88,
    sx,
}) {


  const ToolbarComponent = components.Toolbar;

  const handleCheckboxChange = useCallback((id) => {
    const newSelection = selectionModel.includes(id)
      ? selectionModel.filter((rowId) => rowId !== id)
      : [...selectionModel, id];
    onSelectionModelChange?.(newSelection);
  }, [selectionModel, onSelectionModelChange]);


  const handleSelectAll = useCallback((e) => {
    if (e.target.checked) {
      onSelectionModelChange?.(rows.map(row => row.id));
    } else {
      onSelectionModelChange?.([]);
    }
  }, [rows, onSelectionModelChange]);

  return (
    <Stack sx={{ ...sx,overflow: 'auto',
        '&::-webkit-scrollbar': { 
        display: 'none',
      },
      scrollbarWidth: 'none',
      msOverflowStyle: 'none', }}>
      {ToolbarComponent && <ToolbarComponent {...componentsProps.toolbar} />}
      
      {/* Table Header */}
      <Box sx={{
        display: 'flex',
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}>
        {checkboxSelection && (
          <Box sx={{ 
            width: 48, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' ,

          }}>
            <Checkbox
              checked={rows.length > 0 && selectionModel.length === rows.length}
              indeterminate={selectionModel.length > 0 && selectionModel.length < rows.length}
              onChange={handleSelectAll}
            />
          </Box>
        )}
        {columns.map((column) => (
          <Box
            key={column.field}
            sx={{
              width: column.width || 150,
              flexShrink: 0,
              px: 2,
              py: 1,
              fontWeight: 'fontWeightBold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {column.headerName}
          </Box>
        ))}
      </Box>

      {/* Table Rows */}
      <Box sx={{ minHeight: 400 }}>
        {rows.map((row) => (
          <Box
            key={row.id}
            sx={{
              display: 'flex',
              height: rowHeight,
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'divider',
              '&:hover': { bgcolor: 'action.hover' },
            }}
          >
            {checkboxSelection && (
              <Box sx={{ 
                width: 48, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
              }}>
                <Checkbox
                  checked={selectionModel.includes(row.id)}
                  onChange={() => handleCheckboxChange(row.id)}
                />
              </Box>
            )}
            {columns.map((column) => (
              <Box
                key={column.field}
                sx={{
                  width: column.width || 150,
                  flexShrink: 0,
                  px: 2,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {column.renderCell ? column.renderCell({ value: row[column.field], row }) : row[column.field]}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Stack>
  );
}