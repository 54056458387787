import {DateTime} from 'luxon';

export default function getRamadanTimes() {
	const start = DateTime.now();

	if (DateTime.now().hour <= 3) {
		return {
			start: start.minus({day: 1}).toFormat(`yyyy-MM-dd'T'06:00`),
			end: start.toFormat(`yyyy-MM-dd'T'06:00`),
		};
	} else {
		return {
			start: start.toFormat(`yyyy-MM-dd'T'06:00`),
			end: start.plus({day: 1}).toFormat(`yyyy-MM-dd'T'06:00`),
		};
	}
}

// console.log(getRamadanTimes());
