import {Button, Divider, Icon, IconButton, Stack, TextField, Typography} from '@mui/material';
import {Fragment, useState} from 'react';
import DiscountPicker from 'src/pages/products/common/DiscountPicker';
import {fixPositiveNumbers, isNullOrEmpty, updateArray} from 'src/utils/helperMethods';
import ProductPurchaseItemRecord from '../common/ProductPurchaseItemRecord';
import {printProductPrice} from 'src/features/printer';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import {canAccess} from 'src/utils/permissions';
import UpdateProductQtyModal from '../common/UpdateProductQtyModal';
import AddProductQtyModal from '../common/AddProductQtyModal';
import Price from 'src/components/Price';
import DeleteIcon from '@mui/icons-material/Delete';
import {getNumber} from 'src/utils/numberUtils';
import InfoTip from 'src/components/ui/InfoTip';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import ProductBranchesAvailability from 'src/pages/products/common/ProductBranchesAvailability';
import BranchPicker from 'src/components/BranchPicker';
import useUser from 'src/hooks/useUser';
import {watchCalls} from 'src/utils/devUtils';
import {useModal} from 'src/hooks/useModal';
import {getTestFlag} from 'src/getTestFlag';
import Dev from 'src/components/Dev';
import { Link } from 'react-router-dom';
import LinkToInventory from 'src/components/LinkToInventory';
import {
	AddProductsQuantityPermission,
	updateProductPermission,
	UpdateProductsQuantityPermission,
} from 'src/permissionsList';
import AddQuantityButton from '../common/AddQuantityButton';

export default function VariationsForm({
	showSort = false,
	variations,
	setVariations: _setVariations,
	values,
	refetch,
	productId,
}) {
	const {user} = useUser();
	const branch = user.branch;
	const [state, setState] = useState(0);
	const [isOpen, setIsOpen] = useState({});
	const {open, close} = useModal();
	const properties = variations?.[0]?.values?.flatMap((v) => v?.property?.name) ?? [];
	const setVariations = watchCalls(_setVariations, 'setVariation');

	const canUpdateProduct = canAccess(updateProductPermission);
	const updateVariations = (variation, updates) => {
		if (variation.id) setVariations(updateArray(variations, {...variation, ...updates}, 'id'));
		else setVariations(updateArray(variations, {...variation, ...updates}, 'name'));
	};
	return (
		<Stack spacing={4} mt={4}>
			{/* we don't want to show sort in add product page because variatons doesn't contain required info */}
			{showSort && properties.length > 1 && (
				<Stack alignItems="center" spacing={0} direction="row">
					<Typography fontWeight={'500'}>ترتيب حسب: </Typography>
					{properties.map((p, index) => {
						return (
							<Fragment key={index}>
								<Button sx={{color: state !== index && 'gray'}} onClick={(e) => setState(index)}>
									{p}
								</Button>
								{index < properties.length - 1 && '-'}
							</Fragment>
						);
					})}
				</Stack>
			)}

			{variations
				.sort((a, b) => {
					const propsA = a.name.split('-')[state];
					const propsB = b.name.split('-')[state];

					return propsA.localeCompare(propsB);
				})
				.sort((a, b) => (a.deleted ? 1 : 0) - (b.deleted ? 1 : 0))
				.map((variation, index) => {
					const shouldShowPriceEdit = true; // vars.price || vars.discount;
					const shouldFollowBasePrice = variation.price == null;
					const shouldFollowBaseDiscount =
						variation.discount == null && values.discount && shouldFollowBasePrice;

					const priceToUse = getNumber(variation.price, values.price);
					const discountToUse = !isNullOrEmpty(variation.discount)
						? variation.discount
						: variation.price
							? 0
							: values.discount;

					return (
						<Stack
							p={2}
							key={variation.name}
							spacing={2}
							bgcolor={index % 2 === 0 && '#FAFAF5'}
							sx={{border: '1px dashed gray', borderRadius: 2}}
						>
							<Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
								<Typography fontWeight={'bold'} sx={{opacity: variation.deleted && 0.4}} variant="h6">
									{variation.name}
								</Typography>

								{canUpdateProduct && (
									<>
										{variation.deleted ? (
											<Button
												key={variation.name}
												onClick={() => updateVariations(variation, {deleted: false})}
											>
												استرجاع
											</Button>
										) : (
											<IconButton
												key={variation.name}
												sx={{p: 0, m: 0}}
												onClick={() => updateVariations(variation, {deleted: true})}
											>
												<DeleteIcon color="error" fontSize="small" />
											</IconButton>
										)}
									</>
								)}
							</Stack>

							{/* quantity */}
							{/* if it's new variation we will show text fields only, else we will show button to add quantity */}
							{!values.infinite &&
								(variation.id == null ? (
									<Stack direction={{md: 'row'}} spacing={{xs: 1, md: 2}}>
										<TextField
											id="addedQuantity"
											name="addedQuantity"
											label="الكمية"
											autoComplete="off"
											sx={{flex: '1 1 auto'}}
											type="number"
											onWheel={(e) => e.target.blur()}
											value={variation.addedQuantity}
											onChange={(e) =>
												updateVariations(variation, {addedQuantity: fixPositiveNumbers(e.target.value)})
											}
										/>
										<TextField
											name="cost_per_unit"
											label="تكلفة القطعة"
											autoComplete="off"
											sx={{flex: '1 1 auto'}}
											type="number"
											value={variation.cost_per_unit}
											onWheel={(e) => e.target.blur()}
											onChange={(e) =>
												updateVariations(variation, {cost_per_unit: fixPositiveNumbers(e.target.value)})
											}
											// {...getFieldProps('costPerUnit')}
											// {...getHelperProps('costPerUnit')}
										/>
										{/* NOTE: if u made change make sure to do it for the added quantity */}
										{!branch && (
											<BranchPicker
												allowNone={false}
												value={variation.branch ?? ''}
												onChange={(e) => updateVariations(variation, {branch: e.target.value})}
												variant="outlined"
											/>
										)}
									</Stack>
								) : (
									<Stack spacing={0}>
										<Stack
											px={1}
											py={1}
											border={'1px solid #dbdee0'}
											borderRadius={1}
											spacing={2}
											flex={'1 1 auto'}
										>
											<Stack
												direction={'row'}
												spacing={1}
												justifyContent="space-between"
												alignItems={'center'}
											>
												<Stack spacing={1} direction="row">
													<Typography flexGrow={1}>الكمية</Typography>
													<Typography variant={'subtitle1'}>{variation?.derived?.branch_quantity}</Typography>
												</Stack>

												<Stack spacing={1} direction="row" justifyContent="space-between">
													{canAccess(AddProductsQuantityPermission) && (
														<AddQuantityButton
															productId={productId}
															variationId={variation.id}
															refetch={refetch}
														/>
													)}
												</Stack>
											</Stack>
										</Stack>
										{canAccess(UpdateProductsQuantityPermission) && (
											<Button
												onClick={(e) =>
													open({
														title: 'تعديل الكمية',
														children: (
															<UpdateProductQtyModal
																onSuccess={() => {
																	refetch();
																	close();
																}}
																productId={productId}
																variationId={variation.id}
															/>
														),
													})
												}
												sx={{
													color: 'gray',
													display: 'inline-flex',
													justifyContent: 'center',
													alignItems: 'center',
													width: 'auto',
													minWidth: 'unset',
													flex: 'none',
													alignSelf: 'flex-end',
													fontSize: '9px',
													mt: 0,
													mb: 0,
													padding: '4px 8px',
												}}
											>
												تصحيح الكمية
											</Button>
										)}
									</Stack>
								))}
							{(shouldShowPriceEdit || isOpen[variation.name]) && (
								<Stack>
									<Stack direction="row" spacing={2} alignItems={'center'}>
										<TextField
											disabled={variation.deleted}
											InputLabelProps={{shrink: true}}
											sx={{opacity: variation.deleted && 0.4, flex: '1 1 auto'}}
											key={`${variation.name}-price`}
											onWheel={(e) => e.target.blur()}
											onKeyDown={(e) => {
												if (/^[0-9]*$/.test(e.key) || e.key === 'Backspace' || e.key === 'Delete') {
													return;
												}
												e.preventDefault();
											}}
											onChange={(e) => {
												updateVariations(variation, {price: fixPositiveNumbers(e.target.value)});
											}}
											value={variation.price}
											placeholder={
												`في حالة ترك هذا الحقل فارغاً سيتم استخدام السعر الأساسي` +
												` = ${getNumber(values.price)}`
											}
											type="text"
											inputMode="numeric"
											pattern="\d*"
											label="السعر"
										/>

										<DiscountPicker
											sx={{maxWidth: 80, opacity: variation.deleted && 0.4, flex: '1 1 auto'}}
											label="تخفيض"
											key={variation.name}
											name="discount"
											value={variation.discount}
											placeholder={`${values.discount}%`}
											onChange={(discountValue) => {
												updateVariations(variation, {discount: discountValue});
											}}
										/>

										<IconButton
											size="small"
											onClick={() =>
												printProductPrice({
													price: variation.price ?? values.price,
													discount: variation.discount,
												})
											}
										>
											<PrintRoundedIcon fontSize="small" />
										</IconButton>
									</Stack>
									<Stack mt={1} spacing={1} direction="row" alignItems="center">
										<Typography color="grey" variant="body2">
											{getPriceText({
												price: priceToUse,
												discount: discountToUse,
											})}
										</Typography>

										<InfoTip
											sx={{pb: 1}}
											title={`في حالة ترك حقل السعر فارغاً فسيتم استخدام السعر والتخفيض الاساسي`}
										/>
									</Stack>
								</Stack>
							)}

							{values.id && variation.id && (
								<ProductBranchesAvailability
									product={values}
									variationId={variation.id}
									refetch={refetch}
								/>
							)}
							<LinkToInventory productId={productId} variationId={variation.id} />

							{getTestFlag('test_mode') && <Dev object={variation} title="variation" />}
						</Stack>
					);
				})}
		</Stack>
	);
}

const getPriceText = ({price, discount}) => {
	if (!discount) return `السعر النهائي: ${price}`;

	let txt = `السعر: ` + price + ' ';
	txt += ` التخفيض: ` + discount + '% ';
	txt += `= السعر النهائي: ${calcPriceAfterDiscount(price, discount)}`;
	return txt;
};
