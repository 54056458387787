import __subscriptionBundles from './data/subscriptionBundles';
export const version = new Date().toLocaleString('ar-AE', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	hour12: false, // Set to true if you want to use 12-hour format without AM/PM
});
export const vetrinaPhoneNumber = '+218911881155';

export const subscriptionBundles = __subscriptionBundles;
