import {useQuery} from 'react-query';
import {getProperties} from 'src/utils/AdminApi';
import equalId from 'src/utils/equalId';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getVariationOptions2} from 'src/utils/variationUtils';

export default function useProductProperties({variations, setVariations}) {
	const {data: properties = [], refetch} = useQuery(
		['properties'],
		() => {
			return getProperties();
		},
		{
			refetchOnWindowFocus: false,
		},
	);

	const productProperties = getProductProperties(properties, variations);

	const isPropertyValueSelected = (propertyValueId) => {
		return variations.some((variation) =>
			variation.values.some(
				(variationPropertyValue) => variationPropertyValue.id === propertyValueId && !variation.deleted,
			),
		);
	};

	const handleRemovePropertyValue = (propertyValue) => {
		const newVariations = variations
			.map((v) => {
				if (v.values.some((variationPropertyValue) => equalId(variationPropertyValue, propertyValue)))
					return {...v, deleted: true};
				return v;
			})
			.filter((v) => {
				// remove it forever if new variation
				if (v.deleted && !v.id) return false;
				return true;
			});
		setVariations(newVariations);
	};
	// check hook tests
	const handleAddPropertyValue = (propertyValue) => {
		// when new property value added we should create 1-N new variations
		// edge case: variation already exist but deleted:true => just update deleted state to false

		if (isNullOrEmpty(properties)) return;

		// properties array with only selected values + the new propertyValue
		const properties_with_selected_values = productProperties.map((p) => {
			const values = p.values.filter((v) => isPropertyValueSelected(v.id));
			if (equalId(p, propertyValue.property)) values.push(propertyValue);

			return {
				...p,
				values,
			};
		});

		const all_variations_options = getVariationOptions2(properties_with_selected_values);

		const new_variations = all_variations_options.filter((variation) => {
			// keep only variations that include this propertyValue + not exist before (deleted: true)
			return (
				variation.values.some((v) => v.id == propertyValue.id) &&
				!variations.some((v) => haveSameValues(variation, v))
			);
		});

		const updatedVariations = variations.map((v) => {
			if (v.id && v.deleted && v.values.some((v) => v.id == propertyValue.id))
				return {...v, deleted: false};
			return v;
		});

		setVariations([...updatedVariations, ...new_variations]);
	};

	return {
		properties,
		productProperties,
		isPropertyValueSelected,
		handleRemovePropertyValue,
		handleAddPropertyValue,
		refetchProperties: refetch,
	};
}

/**
 *
 * @param {Array<import('vetrina-types').ContentTypeAttributes<'property'>>} properties
 * @param {Array<import('vetrina-types').ContentTypeAttributes<'variations'>>} variations
 */
function getProductProperties(properties, variations) {
	// each variation will have values array like this (it can be more than 1 element)
	/*  {
        "values": [
            {
                "id": 33,
                "value": "40",
                "property": {
                    "id": 13,
                    "name": "المقاس",
                },
            }
        ]
    } */
	if (isNullOrEmpty(properties) || isNullOrEmpty(variations)) return [];
	return properties.filter((property) => {
		const exist_in_variations = variations.some((variation) =>
			variation.values.some((variationPropertyValue) =>
				equalId(variationPropertyValue.property, property),
			),
		);
		return exist_in_variations;
	});
}

function haveSameValues(variation1, variation2) {
	const set1 = new Set(variation1.values.map((v) => v.id));
	const set2 = new Set(variation2.values.map((v) => v.id));

	if (set1.size !== set2.size) return false;
	return [...set1].every((id) => set2.has(id));
}
