import {Alert, Box, Card, Container, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {setUpShippingAccountAction} from 'src/reducers';
import {LoadingView} from 'src/routes';
import {handleLoginError} from 'src/utils/shipping-api/handleShippingErrors';
import * as yup from 'yup';
import Page404 from '../Page404';
import useShippingProviders from './useShippingProviders';
import {DynamicForm} from 'vetrina-ui';
import {TabContext, TabPanel} from '@mui/lab';
import {getNumber} from 'src/utils/numberUtils';

export default function UpdateDeliveryProvider() {
	const {provider} = useParams();
	const {isLoading, data: shippingProviders = []} = useShippingProviders();
	const dispatch = useDispatch();
	const site = useSelector((s) => s.admin.user.OwnedWebsite);
	const [errorMsg, setErrorMsg] = useState();
	const providerDetails = shippingProviders.find((p) => p.id === provider);
	const [qparams, setQparams] = useSearchParams({tab: 0});
	const value = getNumber(qparams.get('tab'));
	const handleChange = (event, newValue) => {
		setQparams({tab: newValue});
	};

	const providerConfig = site?.config?.shippingProviders?.[provider];

	if (isLoading) return <LoadingView />;
	if (!providerDetails) return <Page404 />;

	const {
		id,
		iconWidth = 30,
		iconHeight = 30,
		name,
		bgcolor,
		enabled,
		iconPath = `/third-party-icons/${id}-icon-dark.png`,
		darkIconPath,
	} = providerDetails;
	const imgSrc = iconPath ?? darkIconPath;

	const onSubmit = async ({auto = false, ...credentials}) => {
		setErrorMsg(null);

		const formValues = {
			credentials,
			enabled: true,
			auto,
		};

		try {
			await dispatch(
				setUpShippingAccountAction({
					...providerConfig,
					...formValues,
					provider,
				}),
			);
			toast.success('تم الربط بنجاح');
		} catch (error) {
			// 400 invalid username/password
			// 406 not enabled or not configured correctly
			// 5xx errors from there server
			let [status, errorMessage] = handleLoginError(provider, error);
			return setErrorMsg(errorMessage);
		}
	};
	// we will get form inputs from backend - if not then we will use default inputs (email and password)
	const formConfig = providerDetails.setupForm?.inputs
		? {
				inputs: [
					...providerDetails.setupForm.inputs.map((input) => {
						return {
							...input,
							// if type is password don't set the value
							value: input.type === 'password' ? '' : providerConfig?.credentials[input.name] ?? '',
							validation:
								input.type === 'password'
									? yup.string().required('يرجي ادخال كلمة المرور')
									: yup.string().required('هذا الحقل اجباري'),
						};
					}),
					{
						label: 'ارسال الطلب بشكل تلقائي',
						name: 'auto',
						type: 'checkbox',
						value: providerConfig?.auto === true,
					},
				],
		  }
		: getAccountSetupForm(providerConfig);

	const settingsFormConfig = providerDetails.settingsForm?.inputs
		? {
				inputs: providerDetails.settingsForm.inputs.map((input) => {
					return {
						...input,
						value: providerConfig?.[input.name] ?? '',
					};
				}),
		  }
		: null;
	return (
		<Container>
			<TabContext value={value.toString()}>
				<Box sx={{borderBottom: 1, mb: 1, borderColor: 'divider'}}>
					<Tabs
						variant="scrollable"
						value={value.toString()}
						onChange={handleChange}
						aria-label="basic tabs"
					>
						<Tab label="اعدادات اساسية" value="0" />
						{settingsFormConfig && <Tab label="اعدادات اضافية" value="1" />}
					</Tabs>
				</Box>

				<TabPanel value="0">
					<Card sx={{p: 4, maxWidth: 500, margin: 'auto'}}>
						<Stack py={1} alignItems="center">
							<img src={imgSrc} height={80} />
							<Typography variant="h4">اعدادات الربط </Typography>
						</Stack>
						<Typography sx={{textAlign: 'center', color: 'gray', pb: 4}} variant="subtitle2">
							يرجي ادخال بيانات حسابك
						</Typography>
						<Stack>
							<DynamicForm submitButtonText="تفعيل" config={formConfig} onSubmit={onSubmit} />
						</Stack>
						<Typography my={2}>
							تأكد ان اسم المستخدم/البريد الالكتروني مطابق تماماً لحسابك في شركة الشحن، مع استخدام حروف
							Capital اذا كانت موجودة في اسم المستخدم الخاص بك
						</Typography>
					</Card>
				</TabPanel>
				{errorMsg && (
					<Alert sx={{fontSize: 18, mt: 2}} severity="error">
						{errorMsg}
					</Alert>
				)}
				<TabPanel value="1">
					{settingsFormConfig && (
						<Card sx={{p: 4, maxWidth: 500, margin: 'auto'}}>
							<Box>
								<Typography sx={{textAlign: 'center', color: 'gray', pb: 4}} variant="subtitle2">
									اعدادات اضافية
								</Typography>
								<DynamicForm
									submitButtonText="حفظ التغيرات"
									config={settingsFormConfig}
									onSubmit={async (values) => {
										await dispatch(
											setUpShippingAccountAction({
												...providerConfig,
												...values,
												provider,
											}),
										);
										toast.success('تم حفظ التغيرات');
									}}
								/>
							</Box>
						</Card>
					)}
				</TabPanel>
			</TabContext>
		</Container>
	);
}

const getAccountSetupForm = (config) => {
	return {
		inputs: [
			{
				label: 'البريد الالكتروني',
				placeholder: 'البريد الالكتروني',
				name: 'email',
				type: 'text',
				validation: yup.string().required('يرجي ادخال البريد الالكتروني'),
				value: config?.credentials?.email ?? '',
			},
			{
				label: 'كلمة المرور',
				placeholder: 'كلمة المرور',
				name: 'password',
				type: 'password',
				validation: yup.string().required('يرجي ادخال كلمة المرور'),
				value: '',
			},
			{
				label: 'ارسال الطلب بشكل تلقائي',
				name: 'auto',
				type: 'checkbox',
				value: config?.auto === true,
			},
		],
	};
};
