// material
import {Stack} from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

Logo.propTypes = {
	sx: PropTypes.object,
};

export default function Logo({sx}) {
	return (
		<Stack sx={sx} direction="row" justifyContent="center">
			<img
				height="40px"
				width="auto"
				// style={{objectFit: 'contain', alignSelf: 'center'}}
				src="/logo.png"
			/>
			{/* <Typography variant="h3" color={color}>
				فترينا
			</Typography> */}
			{/* <Typography variant="h3" color="secondary">زون</Typography> */}
		</Stack>
	);
}
