import PropTypes from 'prop-types';
// material
import {
	Box,
	Button,
	Checkbox,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import {toast} from 'react-toastify';
import useBulkActions from 'src/hooks/useBulkActions';
import useMultipleChanges from 'src/hooks/useMultipleChanges';
import {AddProductsQuantityPermission, updateProductPermission} from 'src/permissionsList';
import {deleteProduct, updateProduct, updateVariation, updateVariation2} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber, isBetween, isNumber} from 'src/utils/numberUtils';
import {filter, filterObject} from 'src/utils/objectUtils';
import {canAccess} from 'src/utils/permissions';
import {getProductPrice, getProductQuantity} from 'src/utils/productUtils';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import MuiLink from 'src/components/ui/StyledLink';
import FabSaveButton from 'src/components/buttons/FabSaveButton';
import {mapToObject} from 'src/utils/array';
import {getImageFormat} from 'src/utils/imageUtils';
import {useState} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LoadingButton} from '@mui/lab';
import AddQuantityButton from '../common/AddQuantityButton';

// ----------------------------------------------------------------------

ProductsListView.propTypes = {
	products: PropTypes.array.isRequired,
};
const validateValue = (value, fieldName) => {
	const num = Number(value);
	if (isNaN(num) || num < 0) {
		toast.error(`يجب ادخال ${fieldName} مناسب`);
		return false;
	}
	return true;
};

const headerColumns = [
	{field: 'name', headerName: 'المنتج', width: 250},
	{field: 'price', headerName: 'السعر', width: 150},
	{field: 'discount', headerName: 'التخفيض', width: 150},
	{field: 'quantity', headerName: 'الكمية'},
];

export default function ProductsListView({products, refetch}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	// multiple select states
	const {selections, setSelections, bulkInputs, setBulkInputs, reset} = useBulkActions({
		initialFormValues: {
			price: '',
			quantity: '',
		},
	});
	const numberOfSelectedProducts = Object.keys(selections).length;
	// row changes observer
	const {changes, setChanges, handleValueChange} = useMultipleChanges();
	const {
		changes: variationChanges,
		setChanges: setVariationChanges,
		handleValueChange: handleVariationValueChange,
	} = useMultipleChanges();

	const notAllowedToUpdateProduct = !canAccess(updateProductPermission);

	const canUpdate = canAccess(updateProductPermission);
	const showSave = canUpdate && (!isNullOrEmpty(changes) || !isNullOrEmpty(variationChanges));
	// we need to map each variation alone

	const onSaveChangesClicked = async (e) => {
		e.preventDefault();

		if (!canUpdate) return toast.error('لا يمكنك تحديث المنتجات');

		setIsSubmitting(true);
		// if (isNullOrEmpty(changes.price)) return setChanges({});
		// get products to update

		try {
			for (let key in changes) {
				const change = changes[key];
				if (change?.price !== undefined && !validateValue(change.price, 'سعر')) {
					setIsSubmitting(false);
					return;
				}
				if (change?.discount !== undefined && !validateValue(change.discount, 'تخفيض')) {
					setIsSubmitting(false);
					return;
				}
			}

			for (let key in variationChanges) {
				const variation = variationChanges[key];
				if (variation?.price !== undefined && !validateValue(variation.price, 'سعر')) {
					setIsSubmitting(false);
					return;
				}
				if (variation?.discount !== undefined && !validateValue(variation.discount, 'تخفيض')) {
					setIsSubmitting(false);
					return;
				}
			}

			const productsToUpdate = Object.keys(changes)
				// construct promises
				.map((key) => {
					delete changes[key].type;
					return updateProduct(key, changes[key]);
				});

			const variationsToUpdate = Object.keys(variationChanges)
				// construct promises
				.map((key) => {
					const _changes = variationChanges[key];
					if (_changes.price == '') _changes.price = null;
					if (_changes.discount == '') _changes.discount = null;
					return updateVariation2(key, _changes);
				});

			await Promise.all([...productsToUpdate, ...variationsToUpdate]);

			/* 
	
			await Promise.all(variationsToUpdate); */

			toast.success('تم التحديث بنجاح');
			await refetch();
			setChanges({});
			setVariationChanges({});
		} catch (err) {
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<form onSubmit={onSaveChangesClicked}>
			<div className="show-scrollbar">
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{canAccess() && (
									<TableCell>
										<Checkbox
											// checked if all are selected
											checked={numberOfSelectedProducts === products.length}
											// show (-) if some are selected
											indeterminate={isBetween(numberOfSelectedProducts, 1, products.length - 1)}
											onChange={(e) => {
												if (e.target.checked === false) {
													setSelections({});
												} else {
													let _selections = mapToObject(products, (p) => ({[p.id]: p}));
													setSelections(_selections);
												}
											}}
											// onChange={handleChange1}
										/>
									</TableCell>
								)}
								{headerColumns.map((column, index) => (
									<TableCell key={index}>{column.headerName}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{products.map((product, rowIndex) => {
								product.type = isNullOrEmpty(product.variations) ? 'product' : 'product-with-variations';
								return (
									<TableRow key={rowIndex} sx={{bgcolor: rowIndex % 2 == 0 && '#F6F6F6'}}>
										<ProductRow
											selections={selections}
											setSelections={setSelections}
											product={product}
											changes={changes}
											variationChanges={variationChanges}
											handleValueChange={handleValueChange}
											handleVariationValueChange={handleVariationValueChange}
											refetch={refetch}
											notAllowedToUpdateProduct={notAllowedToUpdateProduct}
										/>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					{showSave && (
						<FabSaveButton
							type="submit"
							loading={isSubmitting}
							onClick={onSaveChangesClicked}
							notAllowedToUpdateProduct={notAllowedToUpdateProduct}
						/>
					)}
				</TableContainer>
			</div>
			{!isNullOrEmpty(selections) && canAccess() && (
				<ProductsBulkActionForm
					changes={changes}
					refetch={refetch}
					selections={selections}
					bulkInputs={bulkInputs}
					setChanges={setChanges}
					reset={reset}
					setBulkInputs={setBulkInputs}
				/>
			)}
		</form>
	);
}

function ProductRow({
	selections,
	setSelections,
	product,
	changes,
	variationChanges,
	handleVariationValueChange,
	handleValueChange,
	notAllowedToUpdateProduct,
	refetch,
}) {
	const [open, setOpen] = useState(product.variations.length < 4);
	const withVariations = product.type == 'product-with-variations';
	return (
		<>
			{canAccess() && (
				<TableCell>
					<Checkbox
						size="small"
						type={'checkbox'}
						onChange={(e) => {
							if (e.target.checked) setSelections({...selections, [product.id]: product});
							else setSelections(filterObject(selections, product.id));
						}}
						checked={selections[product.id] != null}
					/>
				</TableCell>
			)}
			<TableCell>
				<Stack alignItems="center" spacing={1} direction="row" maxWidth={250}>
					<MuiLink to={`/products/${product.id}`}>
						<Stack alignItems="center" spacing={1} direction="row">
							<img
								loading="lazy"
								src={getImageFormat(product.main_image, 'thumbnail')}
								width={44}
								height={44}
							/>
							<Typography>{product.name}</Typography>
						</Stack>
					</MuiLink>

					{withVariations && (
						<IconButton
							type="button"
							onClick={() => {
								setOpen((o) => !o);
							}}
						>
							<ExpandMoreIcon sx={{rotate: open && '180deg'}} />
						</IconButton>
					)}
				</Stack>
			</TableCell>

			{withVariations ? (
				open ? (
					<>
						<TableCell>
							{product.variations.map((item) => {
								const change = variationChanges[item.id] ?? {};
								let price = Object.hasOwn(change, 'price') ? change.price : item.price;

								return (
									<TableRow>
										<Stack spacing={1} alignItems="center" direction="row">
											<Typography width={100}>{item.name}</Typography>
											<TextField
												disabled={notAllowedToUpdateProduct}
												size="small"
												sx={{minWidth: 60}}
												value={price ?? ''}
												placeholder={`${getNumber(product.price)}`}
												onChange={(e) => handleVariationValueChange(e.target.value, item, 'price')}
											/>
										</Stack>
									</TableRow>
								);
							})}
						</TableCell>
						<TableCell>
							{product.variations.map((item) => {
								const change = variationChanges[item.id] ?? {};
								const price = Object.hasOwn(change, 'price') ? change.price : item.price;
								const discount = Object.hasOwn(change, 'discount') ? change.discount : item.discount;

								const shouldFollowBaseDiscount = discount == null && price == null;

								return (
									<TableRow>
										<TextField
											disabled={notAllowedToUpdateProduct}
											size="small"
											sx={{minWidth: 60}}
											value={discount ?? ''}
											onChange={(e) => handleVariationValueChange(e.target.value, item, 'discount')}
											placeholder={
												shouldFollowBaseDiscount && product.discount != null ? `${product.discount}%` : ''
											}
										/>
									</TableRow>
								);
							})}
						</TableCell>
						<TableCell>
							{product.variations.map((item) => {
								const change = variationChanges[item.id];

								return (
									<TableRow>
										<TextField
											disabled={notAllowedToUpdateProduct}
											disabled
											size="small"
											sx={{minWidth: 60}}
											value={item.derived.branch_quantity}
										/>
									</TableRow>
								);
							})}
						</TableCell>
						<TableCell>
							{product.variations.map((item) => {
								return (
									<TableRow>
										<Box sx={{py: 1}}>
											{canAccess(AddProductsQuantityPermission) && (
												<AddQuantityButton refetch={refetch} productId={product.id} variationId={item.id} />
											)}
										</Box>
									</TableRow>
								);
							})}
						</TableCell>
					</>
				) : (
					<>
						<TableCell>
							<Typography>{getProductPrice(product, false, false)}</Typography>
						</TableCell>
						<TableCell></TableCell>
						<TableCell>{getProductQuantity(product)}</TableCell>
					</>
				)
			) : (
				// PRODUCT WITHOUT VARIATIONS
				<>
					<TableCell>
						<TextField
							size="small"
							sx={{minWidth: 60}}
							disabled={product.type == 'product-with-variations' || notAllowedToUpdateProduct}
							value={changes[product.id]?.price ?? product.price}
							onChange={(e) => handleValueChange(e.target.value, product, 'price')}
						/>
					</TableCell>
					<TableCell>
						<TextField
							size="small"
							sx={{minWidth: 60}}
							disabled={product.type == 'product-with-variations' || notAllowedToUpdateProduct}
							value={changes[product.id]?.discount ?? product.discount}
							onChange={(e) => handleValueChange(e.target.value, product, 'discount')}
						/>
					</TableCell>
					<TableCell>
						{/* <TextField
															size="small"
															sx={{minWidth: 60}}
															disabled={product.type == 'product-with-variations' || notAllowedToUpdateProduct}
															value={changes[product.id]?.quantity ?? getProductQuantity(product)}
															onChange={(e) => handleValueChange(e.target.value, product, 'quantity')}
														/> */}

						<TextField disabled size="small" sx={{minWidth: 60}} value={getProductQuantity(product)} />
					</TableCell>
					<TableCell>
						<AddQuantityButton disabled={product.infinite} refetch={refetch} productId={product.id} />
					</TableCell>
				</>
			)}
		</>
	);
}
function ProductsBulkActionForm({
	changes,
	refetch,
	selections,
	bulkInputs,
	setChanges,
	reset,
	setBulkInputs,
}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const submit = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		const validateUpdates = (obj) => {
			if (obj?.price !== undefined && !validateValue(obj.price, 'سعر')) {
				setIsSubmitting(false);
				return false;
			}
			if (obj?.discount !== undefined && !validateValue(obj.discount, 'تخفيض')) {
				setIsSubmitting(false);
				return false;
			}
			return true;
		};
		if (!validateUpdates(bulkInputs)) return;
		const productsToUpdate = Object.keys(selections).flatMap((id) => {
			// remove empty || null from changes object
			const updates = filter(bulkInputs, (value) => !isNullOrEmpty(value) && isNumber(value));

			const product = selections[id];
			// return promise to update price and quantity of the product
			if (isNullOrEmpty(product.variations)) {
				return updateProduct(id, updates);
			} else {
				// return promise to update price and quantity of each variation
				return product.variations.map((variation) => updateVariation2(variation.id, updates));
			}
		});

		try {
			await Promise.all(productsToUpdate);
			// clear changes from selected items
			// handle the case if user was changing row then try to use bulk action with that row
			// remove all products ids from changes if they exist in selections
			setChanges(filterObject(changes, Object.keys(selections)));
			// clear selections
			reset();
			await refetch();
		} catch (err) {
			toast.error('حدث خطأ غير متوقع');
		}
		setIsSubmitting(false);
	};

	const deleteSelected = async () => {
		setIsDeleting(true);
		const productsToDelete = Object.keys(selections).map((id) => deleteProduct(id));
		await Promise.all(productsToDelete);
		setIsDeleting(false);
		reset();
		refetch();
	};
	const inputMinSize = 75;
	return (
		<Stack
			sx={{
				zIndex: 10,
				position: 'fixed',
				bottom: 50,
				left: '50%',
				transform: 'translate(-50%, -50%)',
				background: 'white',
				p: 2,
				borderRadius: 3,
				boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
			}}
			spacing={1}
			direction="row"
			flex={1}
		>
			<Stack flex={1} spacing={2} direction="row">
				<TextField
					sx={{minWidth: inputMinSize}}
					type="number"
					label="السعر"
					name="price"
					value={bulkInputs.price}
					onChange={(e) => setBulkInputs({...bulkInputs, price: e.target.value})}
					InputLabelProps={{shrink: true}}
				/>
				<TextField
					sx={{minWidth: inputMinSize}}
					type="number"
					label="التخفيض"
					name="discount"
					value={bulkInputs.discount}
					onChange={(e) => setBulkInputs({...bulkInputs, discount: e.target.value})}
					InputLabelProps={{shrink: true}}
				/>
				{/* <TextField
					sx={{minWidth: inputMinSize}}
					type="number"
					InputLabelProps={{shrink: true}}
					label="الكمية"
					name="quantity"
					value={bulkInputs.quantity}
					onChange={(e) => setBulkInputs({...bulkInputs, quantity: e.target.value})}
				/> */}
				<LoadingButton
					sx={{mx: 4}}
					type="button"
					disabled={isSubmitting}
					loading={isSubmitting}
					onClick={submit}
				>
					حفظ
				</LoadingButton>
			</Stack>
			{!isDeleting ? (
				<DeleteAlertDialog disabled={isDeleting} onDelete={deleteSelected} />
			) : (
				<LoadingButton sx={{mx: 4}} loading={isDeleting}></LoadingButton>
			)}
		</Stack>
	);
}
