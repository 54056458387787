import {handleRequestError} from 'src/utils/helperMethods';

const formatCreateOrderError = (error) => {
	/* {
        "id": "CART_NOT_VALID",
        "errors": [
            {
                "product": { },
                "errors": {
                    "variationOutOfStock": true
                },
                "item": { }
            }
        ],
        "updatedItems": [ ]
    } */

	// return message to toast it
	// return items with issue, but we need a way to map them in case of variation
	// we may show modal that list each item and it's issue

	const msg = handleRequestError(error, (status, responseBody) => {
		const errorBody = responseBody;

		if (status === 406) {
			const errorId = errorBody?.id;
			if (errorId === 'CART_EMPTY') {
				return 'يجب اختيار منتجات لإدخال الطلب';
			} else if (errorId === 'CART_NOT_VALID') {
				return 'يوجد مشكلة في المنتجات المختارة، يرجى التأكد من السعر وتوفر الكمية';
			}

			return 'حدث خطأ غير متوقع';
		}

		return errorBody?.message || 'حدث خطأ غير متوقع';
	});
	return {errorMessage: msg};
};

export default formatCreateOrderError;
