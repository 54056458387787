import * as Yup from 'yup';

const commonValidation = {
	images: Yup.array(),
	addedQuantity: Yup.number().typeError('يجب إدخال رقم').min(1, 'لا يمكن إدخال رقم أقل من 1'),
	cost_per_unit: Yup.number().typeError('يجب إدخال رقم').min(0, 'لا يمكن إدخال رقم أقل من 0'),
	name: Yup.string().required('يجب ادخال اسم المنتج '),
	price: Yup.number().typeError('ادخل رقم صحيح').min(0, 'يجب ادخال السعر'),
	// weight: Yup.number().typeError('يجب ادخال وزن صحيح بالكيلو').min(0, 'ادخل وزن صحيح'),
	attrs: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required(),
			value: Yup.string().required(),
		}),
	),
};
export const updateProductSchema = Yup.object().shape({
	...commonValidation,
});

export const addProductSchema = Yup.object().shape({
	...commonValidation,
	images: Yup.array(),
});
