import * as yup from 'yup';

export const createOrderSchema = yup.object().shape({
	name: yup.string(),
	// .required('يرجي ادخال اسم الزبون'),
	email: yup.string().email('يرجي ادخال بريد الكتروني صحيح'),
	phone: yup
		.string('')
		// .required('يرجي ادخال رقم الهاتف')
		.min(9, 'رقم الهاتف يجب ان يتكون من ٩ ارقام'),
	address: yup.string(), //.required('يرجي ادخال العنوان'),
	city: yup.string().when('delivery_method', (delivery_method, field) => {
		if (delivery_method === 'delivery') {
			return field.required('يرجي اختيار المدينة');
		} else {
			// no need for validation
			return field;
		}
	}),
	district: yup.string().when('city', (city, field) => {
		const cityHasDistricts = city === 'طرابلس' || city === 'بنغازي';
		if (cityHasDistricts) {
			// district is required when city has districts
			return field.required('يرجي اختيار المنطقة');
		} else {
			// no need for validation
			return field;
		}
	}),
	discount: yup.number().typeError('يرجي ادخال رقم صحيح').min(0, 'قيمة الخصم يجب ان تكون موجبة'),
});
