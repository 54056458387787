import {Typography} from '@mui/material';
import React from 'react';

/**
 * A component that displays a page title using Typography.
 *
 * @param {object} props - The props object.
 * @param {string} props.title - The title text to display.
 * @param {import('@mui/material').SxProps} [props.sx] - Optional styles to apply to the Typography component.
 */

export default function PageTitle({title, variant = 'h3', sx, ...props}) {
	return (
		<Typography sx={sx} variant={variant}>
			{title}
		</Typography>
	);
}
