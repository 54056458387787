//mui
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//hooks & utils
import {useRef} from 'react';
import {defaultImageSrc, getProductPrice} from 'src/utils/productUtils';
import useVisible from 'src/hooks/useVisible';
import {getImageFormat} from 'src/utils/imageUtils';
import StyledLink from 'src/components/ui/StyledLink';
import ProductLink from 'src/components/ProductLink';
import {uuidv4} from 'src/utils/stringUtils';

const should_disable_outstock = true || process.env.NODE_ENV !== 'development'; // this if we forget to return it to true
export default function ProductsGridSelectionItem({
	product,
	handleRemoveItem,
	handleRemoveItemByProductId,
	handleAddItem,
	checkAddedItemExists: isAdded,
}) {
	const {
		isVisible: variationMenuIsVisible,
		show: showVariationMenu,
		hide: hideVariationMenu,
		toggle: toggleVariationMenu,
	} = useVisible();
	const anchorTargetRef = useRef();
	const {id, name, quantity, main_image, variations, price, discount} = product;
	const image = getImageFormat(product.main_image, 'thumbnail');
	const product_is_selected = isAdded(product.id);
	const totalVariationsQuantity = () => {
		let total = 0;
		if (variations.length) {
			variations.forEach((variation) => (total += variation.quantity));
		}
		return total;
	};

	const addItem = (productOrVariation, key, isVariation) => {
		if (isVariation)
			handleAddItem({
				// copy product meta attributes like image,infinite,name
				...product,
				// override using variation values (price,quantity...)
				...productOrVariation,
				price: productOrVariation.price ?? product.price,
				discount: productOrVariation.discount ?? (productOrVariation.price ? null : product.discount),

				name: `${name} - ${productOrVariation.name}`,
				// custom id so we can select/deselect item
				id: uuidv4(),
				isVariation: true,
				// order item setup
				variation: productOrVariation.id,
				product: product.id,
				selectedQuantity: 1,
			});
		else
			handleAddItem({
				...productOrVariation,
				id: uuidv4(),
				product: productOrVariation.id,
				isVariation: false,
				selectedQuantity: 1,
			});
	};

	return (
		<Stack
			width={'184px'}
			height={'304px'}
			border={'1px solid #E0E0E0'}
			borderRadius={2}
			overflow={'hidden'}
		>
			<img
				loading="lazy"
				src={image}
				alt="product"
				style={{objectFit: 'cover', width: '100%', height: '150px'}}
			/>
			<Box p={1} flexGrow={1}>
				<Tooltip title={name} placement={'top'}>
					<ProductLink product={product} textProps={{noWrap: true}} />
				</Tooltip>
				<Typography variant="body2" color={'#646464'}>
					الكمية المتاحة: {product.infinite ? '∞' : product.derived.branch_quantity}
				</Typography>
				<Typography variant="body2" color={'#646464'}>
					السعر: {getProductPrice(product)}
				</Typography>
			</Box>
			<Stack direction={'row'} alignItems={'center'} p={1}>
				{variations.length != 0 ? (
					<>
						<Button
							variant="outlined"
							fullWidth
							ref={anchorTargetRef}
							onClick={() => showVariationMenu()}
						>
							الإختيارات
						</Button>
						<Menu
							onClose={() => hideVariationMenu()}
							anchorEl={anchorTargetRef.current}
							open={variationMenuIsVisible}
						>
							<List dense>
								{variations.map((variation) => {
									// we need this so that we aviod the case of product.id == variation.id
									const key = `variation-${variation.id}`;
									const isSelected = isAdded(product.id, variation.id);

									return (
										<ListItem key={key} disablePadding>
											<ListItemButton
												disabled={
													should_disable_outstock && !product.infinite && variation.derived.branch_quantity == 0
												}
											>
												<ListItemText
													primary={variation.name}
													secondary={`الكمية : ${product.infinite ? '∞' : variation.derived.branch_quantity}`}
												/>
												<Stack mx={2} flex={1} direction="row" justifyContent="space-between" spacing={1}>
													<Button
														size="small"
														variant="contained"
														fullWidth
														color={!isSelected ? 'secondary' : 'primary'}
														onClick={() => {
															addItem(variation, key, true);
														}}
													>
														إضافة
														{/* {isAdded(product.id) ? 'حذف' : 'إضافة'} */}
													</Button>

													<Button
														size="small"
														sx={{opacity: !isSelected && 0}}
														variant="outlined"
														fullWidth
														color="error"
														onClick={() => {
															handleRemoveItemByProductId(product.id, variation.id);
														}}
													>
														حذف
													</Button>
												</Stack>
											</ListItemButton>
										</ListItem>
									);
								})}
							</List>
						</Menu>
					</>
				) : (
					<Stack flex={1} direction="row" justifyContent="space-between" spacing={1}>
						<Button
							variant="contained"
							fullWidth
							color={!isAdded(product.id) ? 'secondary' : 'primary'}
							onClick={() => {
								addItem(product, product.id, false);
							}}
							disabled={
								should_disable_outstock && !product.infinite && product.derived.branch_quantity == 0
							}
						>
							إضافة
							{/* {isAdded(product.id) ? 'حذف' : 'إضافة'} */}
						</Button>
						{product_is_selected && (
							<Button
								variant="outlined"
								fullWidth
								color="error"
								onClick={() => {
									handleRemoveItemByProductId(product.id);
								}}
								disabled={!product.infinite && product.derived.branch_quantity == 0}
							>
								حذف
							</Button>
						)}
					</Stack>
				)}
			</Stack>
		</Stack>
	);
}
