import {Chip, Stack, Typography} from '@mui/material';
import React from 'react';
import AddPropertyValueModal from 'src/components/_dashboard/properties/AddPropertyValueModal';
import {useModal} from 'src/hooks/useModal';
import useProductProperties from './useProductProperties';
import {canAccess} from 'src/utils/permissions';
import {updateProductPermission} from 'src/permissionsList';

export default function ProductPropertiesSelection({variations, setVariations}) {
	const {open, close} = useModal();

	const {
		productProperties,
		isPropertyValueSelected,
		handleRemovePropertyValue,
		handleAddPropertyValue,
		refetchProperties,
	} = useProductProperties({
		variations,
		setVariations,
	});

		const canUpdateProduct = canAccess(updateProductPermission);

		if (!canUpdateProduct) return null;
	

	return productProperties.map((selectedProperty, selectedPropertyIndex) => {
		return (
			<div key={selectedProperty.id}>
				<Stack spacing={1} direction="row" justifyContent="space-between">
					<Typography>{selectedProperty.name}</Typography>
				</Stack>
				{/* we need to check if property value exist in selected properties.values */}
				{/* list of property values */}
				<Stack flexWrap="wrap" gap={1} direction="row">
					{selectedProperty.values.map((propertyValue) => (
						<Chip
							color="primary"
							onClick={() => {
								if (isPropertyValueSelected(propertyValue.id)) {
									if (selectedProperty.values.length <= 1) return;
									handleRemovePropertyValue(propertyValue);
									//
								} else {
									// this to reset all deleted variation that contain checked value
									handleAddPropertyValue(propertyValue);
								}
							}}
							variant={isPropertyValueSelected(propertyValue.id) ? 'filled' : 'outlined'}
							key={propertyValue.id}
							value={propertyValue.id}
							label={propertyValue.value}
						/>
					))}
					<Chip
						onClick={() => {
							open({
								children: (
									<AddPropertyValueModal
										isVisible
										setVisibility={() => close()}
										propertyId={selectedProperty.id}
										productProperties={productProperties}
										onCreate={async () => {
											await refetchProperties();
											close();
										}}
									/>
								),
							});
						}}
						variant="outlined"
						label="+ اضافة خيار جديد"
					/>
				</Stack>
			</div>
		);
	});
}
