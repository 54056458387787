import {Stack, TextField} from '@mui/material';
import {getDateBoundaries} from 'src/utils/date';

/**
 * A component for entering a date or datetime range.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} [props.isDatetime=false] - If true, inputs will accept datetime-local values instead of dates.
 * @param {Object} props.from - Object representing the starting date input.
 * @param {string} [props.from.value] - The current value of the starting date input.
 * @param {string} [props.from.label] - The label for the starting date input (defaults to "من" if not provided).
 * @param {function} props.from.onChange - Callback function invoked when the starting date input changes.
 * @param {Object} props.to - Object representing the ending date input.
 * @param {string} [props.to.value] - The current value of the ending date input.
 * @param {string} [props.to.label] - The label for the ending date input (defaults to "الى" if not provided).
 * @param {function} props.to.onChange - Callback function invoked when the ending date input changes.
 * @param {string} [props.direction='row'] - The direction of the layout for the input fields (e.g., "row" or "column").
 * @param {number} [props.spacing=1] - The spacing between the input fields.
 * @returns {JSX.Element} A stack layout containing two date or datetime input fields.
 */
export default function DateRangeInput({
	isDatetime = false,
	from,
	to,
	direction = 'row',
	spacing = 1,
}) {
	const type = isDatetime ? 'datetime-local' : 'date';

	const getValue = (value) => {
		if (isDatetime) return value ?? '';

		return value?.split('T')[0] ?? '';
	};

	return (
		<Stack spacing={spacing} direction={direction}>
			<TextField
				fullWidth
				InputLabelProps={{shrink: true}}
				pattern="\d{4}-\d{2}-\d{2}"
				label={from.label ?? 'من'}
				placeholder={from.label ?? 'من'}
				onChange={(e) => {
					if (isDatetime) return from.onChange(e.target.value);

					from.onChange(getDateBoundaries(e.target.value).start ?? '');
				}}
				value={getValue(from.value)}
				type={type}
			/>
			<TextField
				fullWidth
				InputLabelProps={{shrink: true}}
				pattern="\d{4}-\d{2}-\d{2}"
				label={to.label ?? 'الى'}
				placeholder={to.label ?? 'الى'}
				onChange={(e) => {
					if (isDatetime) return to.onChange(e.target.value);
					to.onChange(getDateBoundaries(e.target.value).end ?? '');
				}}
				value={getValue(to.value)}
				type={type}
			/>
		</Stack>
	);
}
