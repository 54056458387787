import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
// material
import {Box, Button, Card, Container, Divider, Stack, TextField, Typography} from '@mui/material';
import {Form, FormikProvider} from 'formik';
import {get} from 'lodash';
import {useQuery} from 'react-query';
import Page from 'src/components/Page';
import CheckboxWrapper from 'src/components/inputs/CheckboxWrapper';
import {LoadingView} from 'src/routes';
import {getOrder, request} from 'src/utils/AdminApi';
import {CustomAutoComplete, Picker} from 'vetrina-ui';
import useDhlForm from './useDhlForm';
import buildGetInputProps from 'src/utils/getInputProps';
import InfoTip from 'src/components/ui/InfoTip';
import {useState} from 'react';
import useSite from 'src/hooks/useSite';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {toast} from 'react-toastify';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import {ScrollToFieldError} from 'src/hooks/ScrollToFieldError';

/**
 *
 * @param {object} param0
 * @param {import('vetrina-types').ContentTypeAttributes<'orders'>} param0.order
 * @returns
 */
export function CreateDhlForm({order}) {
	const navigate = useNavigate();

	const dhlConfig = useSite().config?.shippingProviders?.dhl;

	const {formik, send_inputs, receiver_inputs, package_inputs, info_inputs, items_inputs} =
		useDhlForm({
			order,
			dhlConfig,
			// shipper_cities_options,
		});

	const {data: packages_response} = useQuery(['packages', order.id], () =>
		request(`getDhlOrderPackages?orderId=${order.id}`),
	);
	const packages = packages_response?.packages ?? [];
	const getInputProps = buildGetInputProps(formik);
	console.log('formik', formik.errors);

	const renderSingleInput = (input) => {
		if (typeof input === 'string') return <Typography>{input}</Typography>;
		const {type, htmlType = 'text', ...item} = input;
		const commonProps = {
			fullWidth: item.fullWidth ?? true,
			...getInputProps(item.name),
			...item,
		};

		if (type === 'autocomplete') {
			return <CustomAutoComplete {...commonProps} />;
		} else if (type === 'picker') {
			return <Picker {...commonProps} />;
		} else if (type === 'checkbox') {
			return <CheckboxWrapper {...commonProps} />;
		} /* else if(type === 'datetime-local'){
			return 		<TextField
			{...commonProps} 
			InputLabelProps={{
				shrink: true,
			}}
			type="datetime-local"
		/>

		}  */ else {
			return <TextField {...commonProps} type={htmlType ?? 'text'} />;
		}
	};
	const renderInputs = (inputOrInputs, direction = 'row') => {
		if (Array.isArray(inputOrInputs)) {
			const center = direction === 'row' ? {alignItems: 'flex-start'} : {};
			return (
				<Stack {...center} flex={1} direction={direction ?? 'row'} spacing={2}>
					{/* <Stack flex={1} direction="row" spacing={2}> */}
					{inputOrInputs.map((inp) => {
						return renderInputs(inp);
					})}
					{/* </Stack> */}
				</Stack>
			);
		}
		return (
			<Stack alignItems="center" flex={1} direction="row">
				{renderSingleInput(inputOrInputs)}
				{inputOrInputs.info && (
					<InfoTip title={<p style={{fontSize: 20, textAlign: 'center'}}>{inputOrInputs.info}</p>} />
				)}
			</Stack>
		);
	};

	// useEffect(() => {}, []);

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
				<Stack spacing={2}>
					{/* Sender inputs */}
					<AccordionWrapper
						error={formik.errors?.customerDetails?.shipperDetails}
						parent={<Typography variant="h5">بيانات المرسل</Typography>}
					>
						<Stack spacing={2}>{renderInputs(send_inputs, 'column')}</Stack>
					</AccordionWrapper>

					{/* ----------------  */}
					{/* <Divider /> */}
					{/* Receiver inputs */}
					<AccordionWrapper parent={<Typography variant="h5">بيانات المستلم</Typography>}>
						<Stack spacing={2}>{renderInputs(receiver_inputs, 'column')}</Stack>
					</AccordionWrapper>

					{/* ----------------  */}
					{/* <Divider /> */}
					{/* products inputs */}
					<AccordionWrapper parent={<Typography variant="h5">بيانات المنتجات</Typography>}>
						<Stack spacing={2}>{items_inputs.map((inp) => renderInputs(inp, 'column'))}</Stack>
					</AccordionWrapper>
					{/* ----------------  */}

					{/* Receiver inputs */}
					{/* <Typography variant="h5">بيانات الطرد</Typography>
					<Stack spacing={2}>{package_inputs.map((inp) => renderInputs(inp, 'column'))}</Stack>
					<Divider /> */}
					<AccordionWrapper parent={<Typography variant="h5">بيانات الشحنة</Typography>}>
						<Stack spacing={2}>{renderInputs(info_inputs, 'column')}</Stack>
					</AccordionWrapper>

					{/* <CheckboxWrapper
						onChange={(e) => formik.setFieldValue('info.ag', e.target.checked)}
						label="أوافق على الشروط والأحكام"
					/> */}

					{/* ----------------  */}
					<Stack py={1.5}>
						<Typography variant="h6">الشحنة</Typography>
						{packages.map((_package, index) => {
							const weight = _package.weight;
							const {length, width, height} = _package.dimensions;
							return (
								<>
									<Stack px={2} borderLeft="solid 1px gray" my={2} key={index}>
										<Typography>{index + 1}#</Typography>
										<Typography>
											الابعاد: L{length} x W{width} x H{height}
										</Typography>

										<Typography>الوزن: {weight}kg</Typography>
									</Stack>
									{index != packages.length - 1 && <Divider sx={{my: 0.5}} />}
								</>
							);
						})}
					</Stack>
				</Stack>
				<Stack spacing={4} direction="row" justifyContent="space-between">
					<Button
						disabled={formik.isSubmitting}
						fullWidth
						sx={{mt: 4, py: 1.25}}
						variant="contained"
						type="submit"
					>
						ارسال الشحنة
					</Button>
					<Button
						disabled={formik.isSubmitting}
						fullWidth
						sx={{mt: 4, py: 1.25}}
						variant="outlined"
						onClick={async () => {
							const options = await request('/getCityDeliveryOptions', 'POST', {
								country: get(formik.values, 'customerDetails.receiverDetails.postalAddress.countryCode'),
								city: get(formik.values, 'customerDetails.receiverDetails.postalAddress.cityName'),
								total_weight: get(formik.values, 'content.packages[0].weight'),
								isCustomsDeclarable: get(formik.values, 'info.isCustomsDeclarable'),
								length: get(formik.values, 'content.packages[0].dimensions.length'),
								width: get(formik.values, 'content.packages[0].dimensions.width'),
								height: get(formik.values, 'content.packages[0].dimensions.height'),
								items: order.items,
							});
							if (isNullOrEmpty(options))
								return toast.error('حدث خطأ غير متوقع، يرجي التأكد من العنوان المدخل');

							const dhlOption = options.find((option) => option.id === 'dhl');
							if (!dhlOption) return toast.error('لم يتم العثور على سعر شحن');
							toast.success(`السعر: ${dhlOption.price} يورو`, {position: 'top-center'});
						}}
					>
						عرض السعر
					</Button>
				</Stack>
			</Form>

			<ScrollToFieldError />
		</FormikProvider>
	);
}

export default function CreateDhlPage({}) {
	const {id: orderId} = useParams();

	const {isLoading, data} = useQuery(['order', orderId], () => getOrder(orderId));

	if (isLoading) return <LoadingView />;
	return (
		<Page title="فترينا">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Stack spacing={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography variant="h3">معلومات الشحن </Typography>
						</Stack>

						<CreateDhlForm order={data} />
					</Stack>
				</Card>
			</Container>
		</Page>
	);
}
