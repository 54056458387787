import {DateTime} from 'luxon';

export default function getDhlRequestBody(v, order, dhlConfig) {
	const {info, content, ...values} = v;

	return {
		plannedShippingDateAndTime: DateTime.fromISO(info.plannedShippingDateAndTime)
			.set({second: 0})
			.plus({hours: 1})
			.toUTC()
			.toFormat("yyyy-MM-dd'T'HH:mm:ss 'GMT'ZZ"),
		pickup: {
			isRequested: false,
		},
		productCode: info.productCode,
		localProductCode: info.localProductCode,
		getRateEstimates: true,
		accounts: [
			{
				typeCode: 'shipper',
				number: dhlConfig.accountNumber,
			},
		],
		outputImageProperties: {
			printerDPI: 300,
			encodingFormat: 'pdf',
			imageOptions: [
				{
					typeCode: 'invoice',
					templateName: 'COMMERCIAL_INVOICE_P_10',
					isRequested: true,
					invoiceType: 'commercial',
					languageCode: 'eng',
					languageCountryCode: 'US',
				},
				{
					typeCode: 'waybillDoc',
					templateName: 'ARCH_8x4',
					isRequested: true,
					hideAccountNumber: false,
					numberOfCopies: 1,
				},
				{
					typeCode: 'label',
					templateName: 'ECOM26_84_001',
					renderDHLLogo: true,
					fitLabelsToA4: false,
				},
			],
			splitTransportAndWaybillDocLabels: true,
			allDocumentsInOneImage: false,
			splitDocumentsByPages: false,
			splitInvoiceAndReceipt: true,
			receiptAndLabelsInOneImage: false,
		},
		content: {
			packages: content.packages,
			isCustomsDeclarable: Boolean(info.isCustomsDeclarable),
			declaredValue: info.declaredValue,
			declaredValueCurrency: info.declaredValueCurrency,
			exportDeclaration: {
				lineItems: content.exportDeclaration.lineItems.map((item, index) => ({
					number: index + 1,
					commodityCodes: [
						{
							typeCode: 'outbound',
							value: '190510',
						},
						{
							typeCode: 'inbound',
							value: '190510',
						},
					],
					exportReasonType: 'permanent',
					manufacturerCountry: 'LY',
					isTaxesPaid: true,
					...item,
				})),
				invoice: {
					number: order.tracking_number.toString(),
					date: order.created_at.split('T')[0],
					// instructions: ['Handle with care'],
				},
				exportReference: order.tracking_number.toString(),
				// exportReason: "export reason",
				// exportReasonType: "permanent",
				// shipmentType: 'personal',
			},
			description: info.description,
			incoterm: info.incoterm,
			unitOfMeasurement: info.unitOfMeasurement,
		},
		shipmentNotification: [
			{
				typeCode: 'email',
				receiverId: info.receiverId ?? 'info@alassalsweets.com',
				languageCode: 'eng',
				languageCountryCode: 'UK',
				bespokeMessage: info.bespokeMessage ?? '',
			},
		],
		getTransliteratedResponse: false,
		estimatedDeliveryDate: {
			isRequested: true,
			typeCode: 'QDDC',
		},
		getAdditionalInformation: [
			{
				typeCode: 'pickupDetails',
				isRequested: true,
			},
		],
		...values,
		items: order.items,
		provider: 'dhl',
	};
}
