import React, { useState } from 'react';
import { DateTime } from 'luxon';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function TimePicker({ fullWidth = false, value, label, onChange }) {
  const [open, setOpen] = useState(false);
  const parsedValue = DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
  const [timeValue, setTimeValue] = useState(parsedValue.isValid ? parsedValue : DateTime.local());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAccept = () => {
    onChange(timeValue);
    handleClose();
  };

  const formatDisplayTime = () => {
    if (!DateTime.isDateTime(timeValue)) return '';
    return timeValue.toLocaleString(DateTime.TIME_SIMPLE);
  };

  const handleTimeChange = (type, operation) => {
    const currentValue = timeValue[type];
    let newValue = operation === 'increment' ? currentValue + 1 : currentValue - 1;

    if (type === 'hour') newValue = Math.min(23, Math.max(0, newValue));
    if (type === 'minute') newValue = Math.min(59, Math.max(0, newValue));

    setTimeValue(timeValue.set({ [type]: newValue }));
  };

  const handleDirectInput = (type) => (e) => {
	const value = parseInt(e.target.value) || 0;
	const clampedValue = Math.min(
	  type === 'hour' ? 23 : 59,
	  Math.max(0, value)
	);
	setTimeValue(timeValue.set({ [type]: clampedValue }));
  };

  return (
    <Box sx={{ width: fullWidth ? '100%' : 'auto', direction:'rtl' }} >
      <TextField
        fullWidth={fullWidth}
        label={label}
        value={formatDisplayTime()}
        onClick={handleOpen}
        InputProps={{
          endAdornment: <InputAdornment position="end"><AccessTimeIcon sx={{cursor:"pointer"}}/></InputAdornment>,
        }}
        sx={{
          '& .MuiInputBase-input': {
            cursor: 'pointer',
            caretColor: 'transparent'
          }
        }}
      />

      <Dialog open={open} onClose={handleClose} sx={{direction:'rtl'}}>
        <DialogContent>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'center',
            alignItems: 'center',
            py: 2
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center' }}>
              <IconButton 
                onClick={() => handleTimeChange('hour', 'increment')}
                size="large"
              >
                <ArrowDropUp fontSize="large" color='primary' />
              </IconButton>
			  <TextField
				variant="outlined"
				value={timeValue.hour.toString().padStart(2, '0')}
				onChange={handleDirectInput('hour')}
				InputProps={{
					inputProps: {
					min: 0,
					max: 23,
					style: { 
						fontSize: '1.6rem',
						textAlign: 'center',
						width: 80
					}
					}
				}}
				sx={{ width: 80 }}
				/>
              <IconButton 
                onClick={() => handleTimeChange('hour', 'decrement')}
                size="large"
              >
                <ArrowDropDown fontSize="large" color='primary'/>
              </IconButton>
            </Box>

            <Box sx={{ fontSize: '2rem', pb: 4,mt:3 }}>:</Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton 
                onClick={() => handleTimeChange('minute', 'increment')}
                size="large"
              >
                <ArrowDropUp fontSize="large" color='primary' />
              </IconButton>
			  <TextField
				variant="outlined"
				value={timeValue.minute.toString().padStart(2, '0')}
				onChange={handleDirectInput('minute')}
				InputProps={{
					inputProps: {
					min: 0,
					max: 59,
					style: { 
						fontSize: '1.6rem',
						textAlign: 'center',
						width: 80
					}
					}
				}}
				sx={{ width: 80 }}
				/>
              <IconButton 
                onClick={() => handleTimeChange('minute', 'decrement')}
                size="large"
              >
                <ArrowDropDown fontSize="large" color='primary'/>
              </IconButton>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{  mr:2,pb: 2 , display:"flex", justifyContent:'space-around', alignItems:'center' }}>
          <Button onClick={handleClose} variant='outlined'>الغاء</Button>
          <Button onClick={handleAccept} variant="contained">تأكيد</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TimePicker;