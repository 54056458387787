//Redux
import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
// import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
//Reducer
import {adminAuthReducer} from './reducers/index';
import appReducer from './reducers/app/appReducer';
import modalReducer from './reducers/modal/modalReducer';
import categoryReducer from './reducers/category/categoryReducer';

const rootReducer = combineReducers({
	admin: adminAuthReducer,
	app: appReducer,
	modal: modalReducer,
	category: categoryReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;
