import {IconButton, Stack, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import {handleRequestError} from './utils/helperMethods';

export const ErrorView = ({error, onRefresh}) => (
	<Stack spacing={4} minHeight="100vh" justifyContent="center" alignItems="center">
		<ErrorIcon sx={{fontSize: 100}} color="error" />
		<Typography variant="h3">{handleRequestError(error)}</Typography>

		{typeof onRefresh === 'function' && (
			<IconButton onClick={onRefresh}>
				<Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
					<RefreshIcon />
					<Typography>اعادة المحاولة</Typography>
				</Stack>
			</IconButton>
		)}
	</Stack>
);
