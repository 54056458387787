import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {Button, Dialog, DialogContent, Stack} from '@mui/material';
import AddPropertyModal from './AddPropertyModal';
import DataGrid from 'src/components/DataGrid';

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
	return (
		<Box
			sx={{
				p: 0.5,
				pb: 0,
			}}
		>
			<TextField
				fullWidth
				variant="standard"
				value={props.value}
				onChange={props.onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{
								visibility: props.value ? 'visible' : 'hidden',
							}}
							onClick={props.clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
				sx={{
					// width: {
					//   xs: 1,
					//   sm: 'auto',
					// },
					m: (theme) => theme.spacing(1, 0.5, 1.5),
					'& .MuiSvgIcon-root': {
						mr: 0.5,
					},
					'& .MuiInput-underline:before': {
						borderBottom: 1,
						borderColor: 'divider',
					},
				}}
			/>
		</Box>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

export default function SelectPropertyModal({
	properties,
	setProperties,
	onPropertiesSelected, // function to mutate row object
	propertiesNotSelected,
}) {
	// array of ids as integer
	const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);
	const [open, setOpen] = React.useState(false);

	// theese will be used to add new property (properties)
	const [pModalIsVisible, setPModalVisibility] = React.useState(null);

	const columns = [{field: 'name', headerName: 'الاختيار', width: 150, hideable: false}];

	const confirm = () => {
		const selectedIDs = new Set(selectedRowsIds);
		const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
		// we set valeus as [] to disable selection
		onPropertiesSelected(selectedRowData.map((s) => ({...s, values: []})));
		setOpen(false);
	};

	const [searchText, setSearchText] = React.useState('');
	const [rows, setRows] = React.useState(propertiesNotSelected);

	React.useEffect(() => {
		setRows(propertiesNotSelected);
	}, [propertiesNotSelected]);

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);
		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = [];
		propertiesNotSelected.forEach((property) => {
			// add selected items in the bottom of the list
			if (selectedRowsIds.includes(property.id)) return filteredRows.push(property);

			const match = Object.keys(property).some((field) => {
				return searchRegex.test(`${property[field]}`);
			});
			// add search match to top of the list
			if (match) filteredRows.unshift(property);
		});
		// sort items by best match

		setRows(filteredRows);
	};

	return (
		<div className="App">
			<Button variant="outlined" onClick={() => setOpen(!open)}>
				تحديد
			</Button>
			<Dialog scroll="paper" fullWidth onClose={() => setOpen(false)} open={open}>
				<DialogContent sx={{minHeight: 400}}>
					<DataGrid
						// loading={false}
						rowHeight={88}
						sx={{minHeight: 400}}
						checkboxSelection
						selectionModel={selectedRowsIds}
						onSelectionModelChange={(newSelectionModel) => {
							setSelectedRowsIds(newSelectionModel);
						}}
						components={{Toolbar: QuickSearchToolbar}}
						rows={rows}
						columns={columns}
						componentsProps={{
							toolbar: {
								value: searchText,
								onChange: (event) => requestSearch(event.target.value),
								clearSearch: () => requestSearch(''),
							},
						}}
					/>
					{/* <DialogActions> */}
					<Stack mt={2} spacing={1} direction="row" justifyContent="space-between">
						<Button variant="contained" onClick={confirm}>
							اختيار
						</Button>
						<Button variant="outlined" onClick={() => setPModalVisibility(true)}>
							انشاء اختيار جديد
						</Button>
					</Stack>
					{/* </DialogActions> */}
				</DialogContent>
			</Dialog>
			<AddPropertyModal
				isVisible={pModalIsVisible}
				setVisibility={setPModalVisibility}
				onCreate={(newProperty) => {
					setProperties([...properties, newProperty]);
					setPModalVisibility(false);
				}}
			/>
		</div>
	);
}
