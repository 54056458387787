import {stringify} from 'qs';
import React, {useEffect, useState} from 'react';
import {request} from 'src/utils/AdminApi';
import equalId from 'src/utils/equalId';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {buildCartItemFromOrderItem} from 'src/utils/orderItemUtils';

export default function useDuplicateOrder({order, setOrderItems}) {
	const [isLoading, setisLoading] = useState(order != null);
	useEffect(() => {
		if (!order) return;

		setisLoading(true);

		const query = {
			id_in: order.items.map((item) => item.product.id),
		};
		request(`products?${stringify(query)}`)
			.then((products) => {
				const cart = order.items
					.map((item) => {
						const product = products.find((p) => equalId(p.id, item.product));
						if (!product) return null; // maybe deleted

						let variation;
						if (!isNullOrEmpty(product.variations)) {
							variation = product?.variations?.find((v) => equalId(v.id, item.variation));
							if (!variation) return null; // maybe deleted
						}
						if (item.isDeleted) return null;
						return buildCartItemFromOrderItem({
							...item,
							product,
							variation,
						});
					})
					.filter((a) => a);

				setOrderItems(cart);
				setisLoading(false);
			})
			.catch(() => setisLoading(false));
		// fetch products that match the order items ids
	}, [order]);

	return {
		isLoading,
	};
}
