import {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Stack, Typography} from '@mui/material';
import Page from 'src/components/Page';
import DynamicList from 'src/components/DynamicList';
import equalId from 'src/utils/equalId';
import useUrlQuery from 'src/hooks/useUrlQuery';
import {DateTime} from 'luxon';
import BranchPicker, {useBranches} from 'src/components/BranchPicker';
import {getLoggedInUser} from 'src/utils/selectors';
import useUser from 'src/hooks/useUser';
import {getTestFlag} from 'src/getTestFlag';
import {Box} from '@mui/material';
import {canAccess} from 'src/utils/permissions';
import {sumBy} from 'lodash';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import {useQuery} from 'react-query';
import {getProduct, getProducts, request} from 'src/utils/AdminApi';
import StyledLink from 'src/components/ui/StyledLink';
import Dev from 'src/components/Dev';

export default function ProductInventoryChanges({}) {
	const {id} = useParams();
	const navigate = useNavigate();
	const {variationId} = useUrlQuery();
	const {user} = useUser();

	const api_route = `tenant/products/${id}/inventory-changes`;
	const {data: branches, isLoading} = useBranches();
	let {data, refetch, error} = useQuery(api_route, () => request(api_route));

	const {data: product = {name: '', variations: []}} = useQuery(
		['product', id],
		() => {
			return getProduct(id);
		},
		{
			refetchOnWindowFocus: false,
		},
	);
	const {variations} = product;
	const currentVariation = variations?.find((variation) => variation.id == variationId);
	const [isReady, setIsReady] = useState(false);

	const [branch, setBranch] = useState(user.branch ?? '');
	// if user belong to branch then use user branch
	const branchFilter = user.branch ? user.branch : branch;
	const should_show_branch_filter = !user.branch;

	const location = useLocation();
	const handleChipClicked = (key) => {
		const baseUrl = location.pathname;

		const searchParams = new URLSearchParams(location.search);

		searchParams.set('variationId', variations[key].id);

		navigate(
			{
				pathname: baseUrl,
				search: searchParams.toString(),
			},
			{replace: true},
		);
	};

	// const [state, setState] = useState();
	useEffect(() => {
		if (isLoading) return;

		if (should_show_branch_filter) {
			if (branches?.length > 0) {
				setBranch(branches[0].id);
			}
			setIsReady(true);
		} else {
			setIsReady(true);
		}
	}, [branches]);

	if (!isReady || !data) return;

	const displayedData = data
		.map((item) => {
			return {
				...item,
				quantity: item.type === 'order' ? item.quantity * -1 : item.quantity,
			};
		})
		.filter((d) => {
			if (!branchFilter) return true;
			if (variationId && d.branch === branchFilter) {
				return equalId(d.variation, variationId);
			}
			return d.branch === branchFilter;
		});

	const totalQuantity = sumBy(displayedData, (item) => item.quantity);
	const actualQuantity =
		product.stocks.find(
			(stock) =>
				stock.branch == branchFilter && stock.product == product.id && stock.variation == variationId,
		)?.quantity ?? 0;
	return (
		<Page title="فترينا">
			<Container maxWidth="md">
				<Card sx={{p: 3}}>
					<Stack
						spacing={2}
						direction={{xs: 'column', md: 'row'}}
						sx={{mb: 3, justifyContent: 'space-between', alignItems: 'center', width: '100%'}}
					>
						<Typography variant="h4">تغيرات المخزون للمنتج</Typography>
						{should_show_branch_filter && (
							<BranchPicker
								label="الفرع"
								allowNone={false}
								value={branch}
								onChange={(e, option) => setBranch(option?.id ?? '')}
								options={branches}
							/>
						)}
					</Stack>
					<Stack direction="column">
						<Box>
							<StyledLink to={`/products/${product.id}`}>
								<Typography variant="h6">المنتج: {product.name}</Typography>
							</StyledLink>
							{variationId && (
								<Stack my={1} gap={1} direction="row" flexWrap={'wrap'}>
									{Object.entries(variations).map(([key, val]) => (
										<Button
											key={key}
											variant={currentVariation?.id == val.id ? 'contained' : 'outlined'} // Conditionally set the variant
											color="primary"
											sx={{
												borderRadius: '5px',
												padding: '5px',
												fontSize: '12px',
												fontWeight: 'bold',
												boxShadow: 2,
												'&:hover': {
													boxShadow: 6,
												},
											}}
											onClick={() => handleChipClicked(key)}
										>
											{val.name}
										</Button>
									))}
								</Stack>
							)}
						</Box>
						<Box mt={2}>
							<Typography variant="subtitle1">مجموع الكمية : {totalQuantity} </Typography>
							{getTestFlag('test_mode', false) && (
								<Typography variant="subtitle1">الكمية الفعلية : {actualQuantity} </Typography>
							)}
						</Box>
					</Stack>
					<TableWrapper
						data={displayedData.map((i) => ({
							...i,
							quantity: i.quantity > 0 ? `+${i.quantity}` : i.quantity,
						}))}
						headerColumns={getColumns()}
					/>
				</Card>

				<Box my={2}>
					<Dev
						title={'stock'}
						object={{
							// values,
							// selectedVariations,
							legacy_quantity: product.quantity,
							derived: {
								quantity: product.derived.quantity,
								branch_quantity: product.derived.branch_quantity,
							},
							stocks: product.stocks
								.map((s) => {
									return {
										variation: s.variation,
										branch: s.branch,
										quantity: s.quantity,
									};
								})
								.sort((a, b) => a.branch - b.branch),
							select_variation: getVariationStockAttributes(
								product.variations.find((v) => v.id == variationId),
							),
						}}
					/>
				</Box>
			</Container>
		</Page>
	);
}

const getColumns = () => {
	return [
		// {key: 'id', label: 'id'},
		{
			key: 'type',
			label: '#',
			render: (v, item) => {
				return (
					<Link
						style={{color: '#1B925F'}}
						to={
							v === 'order'
								? `/orders/${item.parent}`
								: item.type === 'purchase'
									? `/purchases/${item.parent}`
									: item.type === 'manual'
										? `/product-quantity-updates/${item.parent}`
										: '#'
						}
					>
						{types[v]}
					</Link>
				);
			},
		},
		getTestFlag('test_mode') && {key: 'branch', label: 'branch'},
		{key: 'quantity', label: 'الكمية'},
		canAccess() && {key: 'price', label: 'السعر'},
		canAccess() && {key: 'total', label: 'المجموع'},
		{
			key: 'created_at',
			label: 'التاريخ',
			getValue: (v) => DateTime.fromISO(v).toFormat('yyyy-MM-dd - HH:mm'),
		},
	].filter((a) => a != null);
};
const types = {
	order: 'طلب',
	purchase: 'مشتريات',
	manual: 'جرد',
	transfer: 'نقل',
};

const getVariationStockAttributes = (v) => {
	if (!v) return null;
	return {
		derived: {
			quantity: v.derived.quantity,
			branch_quantity: v.derived.branch_quantity,
		},
		stocks: v.stocks?.map((s) => {
			return {
				branch: s.branch,
				quantity: s.quantity,
			};
		}),
	};
};